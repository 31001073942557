<template>
  <div>
    <!-- <div class="m-2">

      <h5 class="font-weight-bolder mt-4 mb-2">
        Customer Stats
      </h5>
      <b-row>
        <b-col
          cols="12"
          xl="3"
          lg="12"
          md="12"
          sm="12"
          class=""
        >
          <b-card
            class="shadow-none bg-transparent border-secondary"
          >
            <div class="d-flex flex-row align-items-center">
              <img src="@/assets/images/icons/give-money.png">
              <div class="d-flex flex-column mx-1">
                <h5>
                  Outstanding
                </h5>
                <h4 class="text-black">
                  149.250 KWD
                </h4>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          xl="3"
          lg="12"
          md="12"
          sm="12"
          class=""
        >
          <b-card
            class="shadow-none bg-transparent border-secondary"
          >
            <div class="d-flex flex-row align-items-center">
              <img src="@/assets/images/icons/receipt.png">
              <div class="d-flex flex-column mx-1">
                <h5>
                  Total Orders
                </h5>
                <h4 class="text-black">
                  {{ customer.orders_count }}
                </h4>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          xl="3"
          lg="12"
          md="12"
          sm="12"
          class=""
        >
          <b-card
            class="shadow-none bg-transparent border-secondary"
          >
            <div class="d-flex flex-row align-items-center">
              <img src="@/assets/images/icons/late.png">
              <div class="d-flex flex-column mx-1">
                <h5>
                  Late Order Date
                </h5>
                <h4 class="text-black">
                  16 Aug 2022
                </h4>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

    </div> -->
    <div class="m-2">

      <h5 class="font-weight-bolder mt-4 mb-2">
        Customer Stats
      </h5>
      <b-row>
        <b-col
          cols="12"
          xl="3"
          lg="12"
          md="12"
          sm="12"
          class=""
        >
          <b-card
            class="shadow-none bg-transparent border-secondary"
          >
            <div class="d-flex flex-row align-items-center">
              <img src="@/assets/images/icons/receipt.png">
              <div class="d-flex flex-column mx-1">
                <h5>
                  Total Laundry Orders
                </h5>
                <h4 class="text-black">
                  {{ customer.laundry_orders_count }}
                </h4>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          xl="3"
          lg="12"
          md="12"
          sm="12"
          class=""
        >
          <b-card
            class="shadow-none bg-transparent border-secondary"
          >
            <div class="d-flex flex-row align-items-center">
              <img src="@/assets/images/icons/subscription.png">
              <div class="d-flex flex-column mx-1">
                <h5>
                  Total Subscriptions
                </h5>
                <h4 class="text-black">
                  {{ customer.subscriptions_count }}
                </h4>
              </div>
            </div>
          </b-card>
        </b-col>
<!--        <b-col-->
<!--          cols="12"-->
<!--          xl="3"-->
<!--          lg="12"-->
<!--          md="12"-->
<!--          sm="12"-->
<!--          class=""-->
<!--        >-->
<!--          <b-card-->
<!--            class="shadow-none bg-transparent border-secondary"-->
<!--          >-->
<!--            <div class="d-flex justify-content-between align-items-center">-->
<!--              <div class="d-flex flex-row align-items-center">-->
<!--                <img src="@/assets/images/icons/coupon.png">-->
<!--                <div class="d-flex flex-column mx-1">-->
<!--                  <h5>-->
<!--                    Discount-->
<!--                  </h5>-->
<!--                  <h4 class="text-black">-->
<!--                    {{ customer.order_discount }} %-->
<!--                  </h4>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; <div>-->
<!--                <b-button-->
<!--                  v-b-toggle.customer-form-->
<!--                  variant="flat-primary"-->
<!--                  class="custom-label-primary rounded-pill btn-icon"-->
<!--                >-->
<!--                  <feather-icon-->
<!--                    icon="Edit2Icon"-->
<!--                  />-->
<!--                </b-button>-->
<!--              </div> &ndash;&gt;-->
<!--            </div>-->
<!--          </b-card>-->
<!--        </b-col>-->
<!--        <b-col-->
<!--          cols="12"-->
<!--          xl="3"-->
<!--          lg="12"-->
<!--          md="12"-->
<!--          sm="12"-->
<!--          class=""-->
<!--        >-->
<!--          <b-card-->
<!--            class="shadow-none bg-transparent border-secondary"-->
<!--          >-->
<!--            <div class="d-flex justify-content-between align-items-center">-->
<!--              <div class="d-flex flex-row align-items-center">-->
<!--                <img src="@/assets/images/icons/wallet1.png">-->
<!--                <div class="d-flex flex-column mx-1">-->
<!--                  <h5>-->
<!--                    Subscriptions Balance-->
<!--                  </h5>-->
<!--                  <h4 class="text-black">-->
<!--                    {{ customer.subscriptions_balance }} KWD-->
<!--                  </h4>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div>-->
<!--                <b-button-->
<!--                  v-b-toggle.subscriptions-->
<!--                  variant="flat-primary"-->
<!--                  class="custom-label-primary rounded-pill btn-icon"-->
<!--                >-->
<!--                  <feather-icon-->
<!--                    icon="PlusIcon"-->
<!--                  />-->
<!--                </b-button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </b-card>-->
<!--        </b-col>-->
<!--        <b-col-->
<!--          cols="12"-->
<!--          xl="3"-->
<!--          lg="12"-->
<!--          md="12"-->
<!--          sm="12"-->
<!--          class=""-->
<!--        >-->
<!--          <b-card-->
<!--            class="shadow-none bg-transparent border-secondary"-->
<!--          >-->
<!--            <div class="d-flex justify-content-between align-items-center">-->

<!--              <div class="d-flex flex-row align-items-center">-->
<!--                <img src="@/assets/images/icons/purse.png">-->
<!--                <div class="d-flex flex-column mx-1">-->
<!--                  <h5>-->
<!--                    Wallet Amount-->
<!--                  </h5>-->
<!--                  <h4 class="text-black">-->
<!--                    {{ customer.wallet_amount }} KWD-->
<!--                  </h4>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div>-->
<!--                <b-button-->
<!--                  v-b-toggle.wallet-balance-->
<!--                  variant="flat-primary"-->
<!--                  class="custom-label-primary rounded-pill btn-icon"-->
<!--                >-->
<!--                  <feather-icon-->
<!--                    icon="PlusIcon"-->
<!--                  />-->
<!--                </b-button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </b-card>-->
<!--        </b-col>-->
      </b-row>

    </div>
    <div class="m-2">

      <h5 class="font-weight-bolder mt-4 mb-2">
        Customer Details
      </h5>
      <b-row>
        <b-col
          cols="12"
          xl="6"
          lg="12"
          md="12"
          sm="12"
          class=""
        >
          <b-card
            class="shadow-none bg-transparent border-secondary"
          >
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="text-black font-weight-bolder">
                Basic Info
              </h6>
              <b-button
                v-b-toggle.customer-details-form
                variant="flat-primary"
                class="custom-label-primary rounded-pill btn-icon"
              >
                <feather-icon
                  icon="Edit2Icon"
                />
              </b-button>
            </div>
            <div class="my-1">
              <img
                src="@/assets/images/icons/user.png"
                width="24"
              >
              <span class="mx-2">{{ customer.full_name }}</span>
            </div>
            <div class="my-1">
              <img
                src="@/assets/images/icons/phone.png"
                width="24"
              >
              <span class="mx-2">{{ customer.phone }}</span>
            </div>
            <div
              v-if="customer.email"
              class="my-1"
            >
              <img
                src="@/assets/images/icons/email.png"
                width="24"
              >
              <span class="mx-2">{{ customer.email }}</span>
            </div>
            <div
              v-if="customer.gender"
              class="my-1"
            >
              <img
                src="@/assets/images/icons/gender.png"
                width="24"
              >
              <span class="mx-2">{{ customer.gender === 1 ? 'Male' : 'Female' }}</span>
            </div>
            <div
              class="my-1"
            >
              <img
                src="@/assets/images/icons/discount2.png"
                width="24"
              >
              <span class="mx-2">{{ customer.order_discount }} KWD</span>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <right-sidebar
      v-if="Object.keys(customer).length > 0"
      id="customer-details-form"
      title="Edit Customer"
    >
<!--      <customer-form-->
<!--        :customer="customer"-->
<!--        type="edit"-->
<!--      />-->
      <customer-details-form
        :customer="customer"
        @refreshCustomerDetails="refreshCustomer"
      />
    </right-sidebar>

<!--    <right-sidebar-->
<!--      id="wallet-balance"-->
<!--      title="Add Wallet Balance"-->
<!--    >-->
<!--      <wallet-balance-->
<!--        :customer-id="customer.id"-->
<!--        :phone="customer.phone"-->
<!--        @refreshCustomerDetails="refreshCustomer"-->
<!--      />-->
<!--    </right-sidebar>-->

<!--    <right-sidebar-->
<!--      id="subscriptions"-->
<!--      title="Buy Subscription"-->
<!--    >-->
<!--      <subscriptions-->
<!--        :customer-id="customer.id"-->
<!--        :phone="customer.phone"-->
<!--        @refreshCustomerDetails="refreshCustomer"-->
<!--      />-->
<!--    </right-sidebar>-->
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  VBToggle,
  // BDropdown,
  // BDropdownItem,
} from 'bootstrap-vue'
import CustomerDetailsForm from '@/layouts/components/customers/CustomerDetailsForm.vue'
import CustomerForm from '../CustomerForm.vue'
import RightSidebar from '../../sidebar/RightSidebar.vue'
// import WalletBalance from './WalletBalance.vue'
// import Subscriptions from './Subscriptions.vue'
// import vSelect from 'vue-select'
// import RightSidebar from '../sidebar/RightSidebar.vue'
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    CustomerDetailsForm,
    BCard,
    BRow,
    BCol,
    BButton,
    CustomerForm,
    RightSidebar,
    // WalletBalance,
    // Subscriptions,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  emits: ['refreshCustomerDetails'],
  setup(props, context) {
    const status = ref('')
    const statuses = ref([
      {
        id: 1,
        name: 'to be collected',
        color: 'secondary',
      },
      {
        id: 2,
        name: 'completed',
        color: 'success',
      },
      {
        id: 3,
        name: 'In Progress',
        color: 'warning',
      },
    ])

    const refreshCustomer = () => {
      context.emit('refreshCustomerDetails')
    }

    return {
      status,
      statuses,
      refreshCustomer,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
