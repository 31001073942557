<template>
  <div class="container my-2">
    <validation-observer ref="customerRules">
      <b-form>
        <h3>Basic Info</h3>
        <b-row class="container">
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="first-name"
              >
                First Name <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  id="first-name"
                  v-model="firstName"
                  placeholder="First Name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="last-name"
              >
                Last Name <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  id="last-name"
                  v-model="lastName"
                  placeholder="Last Name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="email"
              >
                Email
              </label>
              <b-form-input
                id="email"
                v-model="email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="phone"
              >
                Phone Number <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Phone Number"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="phone"
                    v-model="phone"
                    placeholder="Phone Number"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="gender"
              >
                Gender
              </label>
              <b-input-group>
                <select
                  id="gender"
                  v-model="gender"
                  class="form-control"
                >
                  <option value="1">
                    Male
                  </option>
                  <option value="2">
                    Female
                  </option>
                </select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="max-debt"
              >
                Maximum Debt
              </label>
              <b-input-group>
                <b-form-input
                  id="max-debt"
                  v-model="maxDebt"
                  placeholder="Maximum Debt"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <hr/>
<!--        <h3>Special Discount</h3>-->
        <b-row class="container">
<!--          <b-col cols="12">-->
<!--            <b-form-group-->
<!--              label-cols-md="12"-->
<!--            >-->
<!--              <label-->
<!--                for="discount"-->
<!--              >-->
<!--                Discount-->
<!--              </label>-->
<!--              <validation-provider-->
<!--                #default="{ errors }"-->
<!--                name="Discount"-->
<!--                rules="between:0,100"-->
<!--              >-->
<!--                <b-input-group>-->
<!--                  <b-form-input-->
<!--                    id="discount"-->
<!--                    v-model="discount"-->
<!--                    placeholder="Enter Percentage"-->
<!--                    :state="errors.length > 0 ? false:null"-->
<!--                  />-->
<!--                </b-input-group>-->
<!--                <small class="text-danger">{{ errors[0] }}</small>-->
<!--              </validation-provider>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
          <b-col md="12">
            <div class="text-danger">
              {{ error }}
            </div>
          </b-col>
          <!-- submit -->
          <b-col md="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1 w-100 my-2"
              @click.prevent="handleSubmitCustomer"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  between,
} from '@validations'
import axios from 'axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    customer: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['refreshCustomerDetails'],
  setup(props, context) {
    const toast = useToast()
    const customerRules = ref(null)

    const firstName = ref('')
    const lastName = ref('')
    const email = ref('')
    const phone = ref('')
    const gender = ref('')
    const maxDebt = ref('')
    const details = ref('')
    const discount = ref('')
    const error = ref('')
    const submitButtonDisabled = ref(false)

    onMounted(() => {
      firstName.value = props.customer.first_name
      lastName.value = props.customer.last_name
      email.value = props.customer.email
      phone.value = props.customer.phone
      gender.value = props.customer.gender
      maxDebt.value = props.customer.maximum_debt
      discount.value = props.customer.order_discount
    })

    const handleSubmitCustomer = () => {
      customerRules.value.validate().then(success => {
        if (success) {
          submitButtonDisabled.value = true

          const data = {
            first_name: firstName.value,
            last_name: lastName.value,
            email: email.value,
            phone: phone.value,
            country_id: 1,
            order_discount: discount.value,
            gender: gender.value,
            maximum_debt: maxDebt.value,
          }

          axios.post(`${process.env.VUE_APP_BASE_API_URL}/pos/user/${props.customer.id}`, data, {
            headers: {
              Authorization: localStorage.getItem('token'),
            },
          }).then(res => {
            submitButtonDisabled.value = false

            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })
            context.emit('refreshCustomerDetails')
            context.root.$emit('bv::toggle::collapse', 'customer-details-form')
          }).catch(err => {
            error.value = err.response.data.message
            submitButtonDisabled.value = false
          })
        }
      })
    }

    return {
      customerRules,
      firstName,
      lastName,
      email,
      phone,
      gender,
      maxDebt,
      details,
      discount,
      handleSubmitCustomer,
      required,
      between,
      error,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
